import React, { Component } from 'react'
import styled from 'styled-components'
import theme from 'ui/styles/theme'

import IconChevron from 'ui/components/generic/IconChevron'


const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;

  background-color: #fff;
`

class ArrowButton extends Component {

  static defaultProps = {
    direction: 'right'
  }

  render() {
    const { direction, ...rest } = this.props

    return (
      <Wrapper {...rest}>
        <IconChevron
          color={theme.colors.primary}
          direction={direction}
        />
      </Wrapper>
    )
  }
}

export default ArrowButton