import React, { Component } from 'react'
import { Route } from 'react-router'

class LanguageProvider extends Component {
  render() {
    return (
      <Route
        path={`/:language(${process.env.REACT_APP_LOCALE_REGEX})?`}
        render={props => <div>{React.cloneElement(this.props.children, props)}</div>}
      />
    )
  }
}

export default LanguageProvider