import React, { Component } from 'react'
import styled, { keyframes, css } from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import Youtube from 'react-youtube'

import Arrow from 'ui/components/generic/Arrow'
import PaddedContent from 'ui/components/generic/PaddedContent'
import PlayButton from 'ui/components/generic/PlayButton'

class Gallery extends Component {
  state = {
    currentItem: 0,
    previousItem: null
  }

  componentDidMount() {
    const { items } = this.props
    if (items.length > 1) {
      this.resetInterval()
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  resetInterval() {
    clearInterval(this.interval)
    this.interval = setInterval(this.changeItem, duration)
  }

  nextItem = () => {
    const { items } = this.props
    const { currentItem } = this.state

    let targetIndex = currentItem + 1
    if (targetIndex >= items.length) {
      targetIndex = 0
    }

    this.changeItem(targetIndex)
  }

  previousItem = () => {
    const { items } = this.props
    const { currentItem } = this.state

    let targetIndex = currentItem - 1
    if (targetIndex < 0) {
      targetIndex = items.length - 1
    }

    this.changeItem(targetIndex)
  }

  changeItem = index => {
    const { items } = this.props
    const { currentItem } = this.state

    // clear interval if we're manually navigating
    if (typeof index !== 'undefined') {
      this.resetInterval()
    }

    // calc next item
    const nextItem =
      typeof index !== 'undefined'
        ? index
        : currentItem === items.length - 1
        ? 0
        : currentItem + 1

    this.setState({
      currentItem: nextItem,
      previousItem: currentItem,
      videoPlaying: null,
      videoLoading: null
    })

    // Clean previous item to avoid weird animations when the previous item is being re-clicked
    this.timeout = setTimeout(
      () => this.setState({ previousItem: null }),
      transitionDuration
    )
  }

  playVideo = index => {
    // stop slider
    clearInterval(this.interval)

    this.setState({ videoLoading: index })
  }

  render() {
    const { items } = this.props
    const { currentItem, previousItem, videoPlaying, videoLoading } = this.state

    return (
      <Wrapper>
        <PaddedContent size="large">
          <Slider single={items.length < 2}>
            {items.map((item, index) => (
              <ItemWrapper
                key={index}
                visible={index === currentItem}
                leaving={index === previousItem}
              >
                <Item src={item.image.sourceUrl}>
                  {item.hasVideo && index === currentItem && (
                    <VideoWrapper>
                      {videoPlaying !== currentItem && (
                        <Play
                          loading={videoLoading === currentItem}
                          onClick={() => this.playVideo(index)}
                        />
                      )}
                      {(videoLoading === currentItem ||
                        videoPlaying === currentItem) && (
                        <Video visible={videoPlaying === currentItem}>
                          <Youtube
                            videoId={item.video}
                            visible={videoPlaying === currentItem}
                            onReady={e => e.target.playVideo()}
                            onError={e =>
                              this.setState({
                                videoPlaying: null,
                                videoLoading: null
                              })
                            }
                            onStateChange={e =>
                              e.data === 1 &&
                              this.setState({
                                videoPlaying: index,
                                videoLoading: null
                              })
                            }
                          />
                        </Video>
                      )}
                    </VideoWrapper>
                  )}
                </Item>
              </ItemWrapper>
            ))}
          </Slider>
          {items.length > 1 && (
            <Navigation>
              <Pagination>
                <strong>{currentItem + 1}</strong> <Seperator>/</Seperator>{' '}
                {items.length}
              </Pagination>
              <Actions>
                <Action direction="left" onClick={this.previousItem} />
                <Action direction="right" onClick={this.nextItem} />
              </Actions>
            </Navigation>
          )}
        </PaddedContent>
      </Wrapper>
    )
  }
}

export const query = `
  items {
    image {
      sourceUrl
    }
    hasVideo
    video
  }
`

export default Gallery

const duration = 7000
const transitionDuration = 1000

const entry = keyframes`
  from {
    opacity: 0
    transform: translateY(10px)
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
`

const Wrapper = styled.div`
  overflow: hidden;
`

const Slider = styled.div`
  position: relative;
  padding-top: 56.25%;
  overflow: hidden;
  margin-bottom: ${props => (props.single ? 50 : 0)}px;

  ${media.xs`
    margin-left: -20px;
    margin-right: -20px;
  `}
`

const ItemWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;

  opacity: ${props => (props.visible ? 1 : 0)};
  transform: translateX(${props =>
    props.visible ? 0 : props.leaving ? -100 : 100}px);
  transition: all ${props =>
    props.visible ? transitionDuration : transitionDuration - 300}ms;

  ${props =>
    !props.visible &&
    !props.leaving &&
    css`
      transition-duration: 0ms;
    `}

  z-index: ${props => (props.visible ? 2 : 1)};
`

const Item = styled.div`
  height: 100%;
  width: 100%;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('${props => props.src}');
`

const Play = styled(PlayButton)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -40px;
  margin-top: -40px;

  animation: ${entry} 800ms 400ms both;
`

const Navigation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  margin-bottom: 50px;

  ${media.xs`
    margin-bottom: 0;
  `}
`

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80px;
`

const Action = styled(Arrow)`
  cursor: pointer;

  &:hover path {
    stroke: ${props => props.theme.colors.primary};
  }
`

const Pagination = styled.div`
  position: relative;
  padding-left: 30px;
  font-size: 14px;
  color: ${props => props.theme.colors.tones.light};

  strong {
    font-weight: inherit;
    color: ${props => props.theme.colors.primary};
  }

  ${media.xs`
    padding-left: 0;
  `}
`

const Seperator = styled.span`
  letter-spacing: -1px;
`

const VideoWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0px;
  height: 100%;
  width: 100%;
`

const Video = styled.div`
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: all 500ms;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
