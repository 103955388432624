import { createGlobalStyle } from 'styled-components'
import styledNormalize from 'styled-normalize'
import { Container } from 'styled-bootstrap-grid'

import responsiveText from 'ui/styles/responsiveText'
import responsiveSpacing from 'ui/styles/responsiveSpacing'
import form from './form'
import slick from './slick'

export default createGlobalStyle`
  ${styledNormalize}

  body {
    margin: 0;
    padding: 0;
    font-family: ${props => props.theme.bodyFont};
    font-weight: ${props => props.theme.fontWeights.light};
    ${responsiveText('sm')}
    -webkit-font-smoothing: antialiased;
    color: ${props => props.theme.colors.text};
    position: relative;
  }

  p {
    margin: 0;
    margin-bottom: 20px;
  }

  h1, h2, h3, h4, h5 {
    margin: 0;
    font-weight: ${props => props.theme.fontWeights.bold};
    ${responsiveSpacing(1)}
  }

  h3, h4, h5 {
    margin-bottom: 15px;
  }

  h1 {
    ${responsiveText('xl')};
  }

  h2 {
    ${responsiveText('lg')}
  }

  h3 {
    ${responsiveText('md')}
  }

  h4 {
    ${responsiveText('sm')}
    font-weight: 600;
  }

  h5 {
    ${responsiveText('xs')}
    font-weight: 600;
  }

  * {
    box-sizing: border-box;
  }

  img, svg {
    display: block;
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.colors.primary};
  }

  ul, ol {
    margin: 0;
    list-style-position: outside;
    padding-left: 20px;
    margin-bottom: 40px;

    li {
      padding-left: 0px;
      margin-bottom: 10px;

      p {
        margin-bottom: 0;
        color: ${props => props.theme.colors.tones.light};
      }
    }
  }

  /**
   * Bootstrap utils
   **/

  .noGutters {
    margin-right: 0;
    margin-left: 0;

    > div {
      padding-right: 0;
      padding-left: 0;
    }
  }

   /**
   * Tweak container padding on small devices
   **/
  ${Container} {

    @media(max-width: ${props => props.theme.grid.breakpoints.xs}px) {
      padding-left: ${props => props.theme.grid.container.paddingXs}px;
      padding-right: ${props => props.theme.grid.container.paddingXs}px;
    }
  }

  ${form}

  ${slick}
`
