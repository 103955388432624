import React from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'styled-bootstrap-grid'

const PaddedContent = ({ children, size, ...rest }) => {
  let md = 8
  let lg = 6
  let xl = 6

  // eslint-disable-next-line
  switch (size) {
    case 'small':
      md = 6
      xl = 4
      break
    case 'large':
      md = 10
      lg = 8
      xl = 8
      break
  }

  return (
    <Container fluid {...rest}>
      <Row>
        <Col
          md={md}
          mdOffset={(12 - md) / 2}
          lg={lg}
          lgOffset={(12 - lg) / 2}
          xl={xl}
          xlOffset={(12 - xl) / 2}
        >
          {children}
        </Col>
      </Row>
    </Container>
  )
}

PaddedContent.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(['small', 'medium', 'large'])
}

PaddedContent.defaultProps = {
  size: 'medium'
}

export default PaddedContent
