import React, { useEffect } from 'react'
import { Switch, Route, useRouteMatch, useLocation } from 'react-router'
import { ThemeProvider } from 'styled-components'
import { BaseCSS, GridThemeProvider } from 'styled-bootstrap-grid'

import GridView from 'ui/components/generic/GridView'
import MediaProvider from 'redux/modules/media/MediaProvider'
import useSiteMeta from 'containers/useSiteMeta'
import theme from 'ui/styles/theme'
import GlobalStyles from 'ui/styles/global'
import AnimatedRoutes from 'ui/components/AnimatedRoutes'
import Navigation from 'ui/components/Navigation'
import Footer from 'ui/components/Footer'
import Page from 'ui/components/Page'
import Loading from 'ui/components/generic/Loading'
import TagManager from 'react-gtm-module'

export default () => {
  const { data: siteMeta, loading: siteMetaLoading } = useSiteMeta()
  const location = useLocation()
  const match = useRouteMatch()

  useEffect(() => {
    // Initialize tag manager
    process.env.NODE_ENV !== 'development' &&
      siteMeta?.siteMeta?.gtmId &&
      TagManager.initialize({ gtmId: siteMeta.siteMeta.gtmId })
  }, [siteMeta])

  if (siteMetaLoading) {
    return <Loading />
  }

  return (
    <ThemeProvider theme={theme}>
      <GridThemeProvider gridTheme={theme.grid}>
        <MediaProvider>
          <BaseCSS />
          <GlobalStyles />
          <Navigation />
          <AnimatedRoutes pathname={location.pathname}>
            <Switch location={location}>
              <Route
                exact
                path={`/:language(${process.env.REACT_APP_LOCALE_REGEX})?/`}
                render={props => (
                  <Page type="page" key={match.url} {...props} />
                )}
              />
              <Route
                exact
                path={`/:language(${process.env.REACT_APP_LOCALE_REGEX})?/:slug+`}
                render={props => <Page key={match.url} {...props} />}
              />
            </Switch>
          </AnimatedRoutes>
          <Footer />
          {process.env.NODE_ENV === 'development' && <GridView />}
        </MediaProvider>
      </GridThemeProvider>
    </ThemeProvider>
  )
}
