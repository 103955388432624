import React, { Component } from 'react'
import { Mutation } from '@apollo/react-components'
import gql from 'graphql-tag'

export default (
  location,
  prop = 'formSubmission',
  functionProp = 'submitForm'
) => WrappedComponent =>
  class WithNav extends Component {
    render() {
      return (
        <Mutation
          mutation={gql`
            mutation SubmitFormEntry($formId: String, $data: String) {
              submitFormEntry(formId: $formId, data: $data) {
                errors
              }
            }
          `}
        >
          {(submitForm, { loading, error, data }) => {
            if (!error) {
              return (
                <WrappedComponent
                  {...this.props}
                  {...{
                    [functionProp]: submitForm,
                    [`${prop}Loading`]: loading,
                    [`${prop}`]:
                      !loading && !error && data && data.submitFormEntry
                  }}
                />
              )
            }
            return <p>Error: {error.message}</p>
          }}
        </Mutation>
      )
    }
  }
