import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './modules'

export default (server = false) => {
  const enhancers = []
  const middleware = [
    thunk
  ];

  if (process.env.NODE_ENV === 'development' && !server) {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension())
    }
  }

  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
  )

  const initialState = !server ? window.__REDUX_STATE__ || {} : {}

  const store = createStore(
    rootReducer,
    initialState,
    composedEnhancers
  )

  return store
}
