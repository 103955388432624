import React, { Component } from 'react'
import styled from 'styled-components'
import { Row, Col, media } from 'styled-bootstrap-grid'

import { BodyText as Text } from 'ui/styles/utils'
import PaddedContent from 'ui/components/generic/PaddedContent'

class ImageAndText extends Component {
  render() {
    const { header, body, image, orientation } = this.props

    return (
      <Wrapper>
        <PaddedContent size="large">
          <Row>
            <Col
              md={5}
              mdOffset={orientation === 'left' ? 1 : 0}
              order={orientation === 'left' ? 2 : 1}
            >
              <TextCol>
                <Header>{header}</Header>
                <Text dangerouslySetInnerHTML={{ __html: body }} size="sm" />
              </TextCol>
            </Col>
            <Col
              md={6}
              mdOffset={orientation === 'left' ? 0 : 1}
              order={orientation === 'left' ? 1 : 2}
            >
              <ImageCol>
                <img src={image.sourceUrl} alt={image.altText} />
              </ImageCol>
            </Col>
          </Row>
        </PaddedContent>
      </Wrapper>
    )
  }
}

export const query = `
  header
  body
  image {
    sourceUrl
    altText
  }
  orientation
`

export default ImageAndText

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
`

const Header = styled(Text).attrs({ as: 'h2' })`
  margin-bottom: 30px;
`

const TextCol = styled.div`
  position: relative;

  margin-bottom: 30px;

  ${media.lg`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0;
  `}
`

const ImageCol = styled.div`
  img {
    width: 100%;
  }
`
