import React from 'react'
import styled from 'styled-components'
import { gql, useQuery } from '@apollo/client'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { Link } from 'react-router-dom'

import useNav from 'containers/useNav'
import getRelativeUrl from 'utils/getRelativeUrl'
import { Text } from 'ui/styles/utils'
import Logo from 'ui/components/generic/Logo'

export default () => {
  const { data: footer, loading: footerLoading } = useQuery(FOOTER_QUERY)
  const { data: menuItems, loading: menuItemsLoading } = useNav('FOOTER_NAV')

  if (footerLoading || menuItemsLoading) return null

  const {
    optionsFooter: { phoneNumber, email, address }
  } = footer

  return (
    <Wrapper>
      <Container fluid>
        <Row>
          <Col sm={12} lg={4}>
            <LogoWrapper>
              <Logo textColor="#fff" />
            </LogoWrapper>
          </Col>
          <Col sm={6} lg={4}>
            <Text as="h2" mb={30}>
              Kontakt
            </Text>
            <Text size="sm">
              <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
            </Text>
            <Text size="sm">
              <a href={`mailto:${email}`}>{email}</a>
              <br />
              <br />
            </Text>
            <Text
              size="sm"
              dangerouslySetInnerHTML={{ __html: address }}
              mb={30}
            />
          </Col>
          <Col sm={6} lg={4}>
            <Text as="h2" mb={30}>
              Oversigt
            </Text>
            <Row>
              {menuItems.map(item => (
                <Col sm={6} key={item.id}>
                  <Link to={getRelativeUrl(item.url)}>{item.label}</Link>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

const FOOTER_QUERY = gql`
  {
    optionsFooter {
      phoneNumber
      email
      address
    }
  }
`

const Wrapper = styled.div`
  padding: 100px 0;
  background-color: ${props => props.theme.colors.primary};
  color: #fff;

  a {
    color: #fff;
  }
`

const LogoWrapper = styled.div`
  margin-bottom: 50px;

  svg {
    width: 100%;
    max-width: 240px;
    height: auto;
  }
`
