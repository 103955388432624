import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import posed, { PoseGroup } from 'react-pose'

import withSubmitFormMutation from 'containers/withSubmitFormMutation'
import Button from 'ui/components/generic/Button'
import Spinner from 'ui/components/generic/Spinner'

import Text from './fields/Text'
import Name from './fields/Name'
import Email from './fields/Email'
import TextArea from './fields/TextArea'

class Form extends Component {
  state = {
    data: {}
  }

  updateValue = (name, value) => {
    this.setState(state => ({
      data: {
        ...state.data,
        [name]: value
      }
    }))
  }

  getField(type) {
    switch(type) {
      case 'name':
        return Name
      case 'email':
        return Email
      case 'textarea':
        return TextArea
      default:
        return Text
    }
  }

  getError(type, error) {
    switch (type) {
      case 'name':
        return null // let the field handle the error it self
      default:
        return (
          <Error key="error">
            <ErrorMessage>{error}</ErrorMessage>
          </Error>
        )
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { submitForm, form: { FormId } } = this.props
    const { data } = this.state
    submitForm({
      variables: {
        formId: "" + FormId,
        data: JSON.stringify(data)
      }
    })
  }

  getConfirmation() {
    const confirmations = JSON.parse(this.props.form.confirmations)
    if (confirmations[1] && confirmations[1].type === 'message') {
      return (
        <Confirmation key="confirmation" dangerouslySetInnerHTML={{ __html: confirmations[1].message }} />
      )
    }
    return <Confirmation key="confirmation"><p>Formularen blev indsendt.</p></Confirmation>
  }

  render() {
    const { form, formSubmission, formSubmissionLoading } = this.props
    const { data } = this.state

    // extract fields from json
    const fields = JSON.parse(this.props.form.fields)

    // validation
    let success = false
    let errors = null
    if (formSubmission) {
      errors = formSubmission.errors && JSON.parse(formSubmission.errors)
      if (!errors) {
        success = true
      }
    }

    return (
      <Wrapper>
        <Container fluid>
          <Row>
            <Col
              md={8}
              mdOffset={2}
              lg={6}
              lgOffset={3}
            >
              <h1 dangerouslySetInnerHTML={{ __html: form.title }} />
              <PoseGroup preEnterPose="preEnter">
                {!success &&
                  <PosedForm key="form" onSubmit={this.handleSubmit}>
                    {!!errors &&
                      <p>{errors.header}</p>
                    }
                    {Object.keys(fields).map(id => {
                      const field = fields[id]
                      const Field = this.getField(field.type)
                      return (
                        <Section key={id}>
                          {!field.label_hide &&
                            <Label
                              dangerouslySetInnerHTML={{ __html: field.label }}
                              required={field.required}
                            />
                          }
                          <Field
                            onChange={this.updateValue}
                            value={data[field.id]}
                            error={errors && errors[field.id]}
                            {...field}
                          />
                          <PoseGroup flipMove={false} withParent={false}>
                            {errors && errors[field.id] && this.getError(field.type, errors[field.id])}
                          </PoseGroup>
                        </Section>
                      )
                    })}
                    <Actions>
                      <Button
                        disabled={formSubmissionLoading}
                        component="button"
                      >
                        {form.submitButtonText}
                      </Button>
                      { formSubmissionLoading &&
                        <Spinner />
                      }
                  </Actions>
                  </PosedForm>
                }
                { success && this.getConfirmation()}
              </PoseGroup>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    )
  }
}

export default withSubmitFormMutation()(Form)

export const query = ``

const defaultPose = {
  enter: {
    opacity: 1,
    x: '0px'
  },
  preEnter: {
    opacity: 0,
    x: '50px'
  },
  exit: {
    opacity: 0,
    x: '-50px'
  }
}

const errorPose = {
  enter: {
    opacity: 1,
    y: '0px'
  },
  preEnter: {
    opacity: 0,
    y: '50px'
  },
  exit: {
    opacity: 0,
    y: '-50px'
  }
}

const Wrapper = styled.div`
  padding: 100px 0;
  background-color: ${props => props.theme.colors.tones.lightest};
`

const Label = styled.label`
  display: block;
  margin-bottom: 10px;

  ${props => props.required && css`
    &:after {
      display: inline-block;
      content: "*";
      margin-left: 5px;
      color: ${props.theme.colors.danger};
    }
  `}
`

const Section = styled.div`
  padding: 20px 0;
`

export const Error = styled(posed.div(errorPose))`
  border-top: 3px solid ${props => props.theme.colors.danger};
`

export const ErrorMessage = styled.div`
  display: inline-block;
  padding: 10px;
  background-color: ${props => props.theme.colors.danger};
  color: #fff;
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  button {
    margin-right: 20px;
  }
`

const PosedForm = posed.form(defaultPose)

const Confirmation = styled(posed.div(defaultPose))`
  padding: 20px 0;
`