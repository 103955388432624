import React from 'react'
import styled from 'styled-components'

import scrollOnMount from 'utils/scrollOnMount'
import usePost from 'containers/usePost'
import useSiteMeta from 'containers/useSiteMeta'
import Modules from 'ui/components/Modules'
import Seo from 'ui/components/Seo'
import NotFound from 'ui/components/NotFound'

function Page(props) {
  const {
    match: {
      params: { slug }
    }
  } = props

  const { data: siteMeta } = useSiteMeta()
  const { data: post, loading, error } = usePost(
    slug || (siteMeta && siteMeta.frontPageSlug) || 'front'
  )

  const isFrontPage = post?.template?.templateName === 'Forside'

  let modules = post && (post.modules || [])

  // Prepend header module if we are not on the front page
  if (modules && !isFrontPage) {
    modules = [{ layout: 'header', post }].concat(modules)
  }

  if (error) {
    return <NotFound />
  }

  return (
    <Wrapper>
      {!loading && <Seo seo={post?.seo} />}
      {modules && (
        <Modules modules={modules} post={post} type={post.__typename} />
      )}
    </Wrapper>
  )
}

export default scrollOnMount(Page)

const Wrapper = styled.div`
  margin-top: ${props => props.theme.navHeight}px;
  background-color: #fff;
  min-height: calc(100vh - ${props => props.theme.navHeight}px);
`
