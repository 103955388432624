import { gql, useQuery } from '@apollo/client'

export default location => {
  const query = gql`
    query($location: MenuLocationEnum) {
      menuItems(where: { location: $location }) {
        nodes {
          id
          label
          url
          childItems {
            nodes {
              id
              label
              url
            }
          }
        }
      }
    }
  `

  const { data, loading, error } = useQuery(query, { variables: { location } })

  if (error) {
    console.error('Error fetching nav', error)
  }

  return {
    data: parseData(data),
    loading,
    error
  }
}

const parseData = data => (data ? data.menuItems && data.menuItems.nodes : [])
