import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import useEventListener from '@use-it/event-listener'

import getRelativeUrl from 'utils/getRelativeUrl'
import useNav from 'containers/useNav'

import Logo from 'ui/components/generic/Logo'
import BVSFLogo from 'ui/components/generic/BVSFLogo'
import MenuIconComponent from 'ui/components/generic/MenuIcon'

function Navigation(props) {
  const location = useLocation()
  const { data: mainNav } = useNav('MAIN_NAV')
  const [overlayVisible, setOverlayVisible] = useState(false)
  const [compact, setCompact] = useState(false)

  const handleScroll = useCallback(() => {
    if (window.pageYOffset > 200) {
      if (!compact) {
        setCompact(true)
      }
    } else {
      if (compact) {
        setCompact(false)
      }
    }
  }, [compact])

  // close overlay when navigation changes
  useEffect(() => {
    if (overlayVisible) {
      setOverlayVisible(false)
    }
  }, [location.pathname]) // eslint-disable-line

  // attache scroll handler
  useEventListener('scroll', handleScroll)

  const closeOverlay = () => setOverlayVisible(false)

  return (
    <Wrapper>
      <Overlay visible={overlayVisible}>
        <OverlayNav visible={overlayVisible}>
          {mainNav.map((item, index) => (
            <OverlayNavItem
              index={index}
              key={item.id}
              active={getRelativeUrl(item.url) === location.pathname}
            >
              <Link to={getRelativeUrl(item.url)}>{item.label}</Link>
              {item.childItems.length > 0 && (
                <ul>
                  {item.childItems.map(child => (
                    <li key={child.id}>
                      <Link to={getRelativeUrl(child.url)}>{child.label}</Link>
                    </li>
                  ))}
                </ul>
              )}
            </OverlayNavItem>
          ))}
        </OverlayNav>
      </Overlay>
      <TopBar>
        <Container fluid>
          <BVSFLogo />
          <KBLogo src="/images/kblogo.png" />
        </Container>
      </TopBar>
      <MainBar compact={compact}>
        <Container fluid>
          <Row alignItems="flex-start">
            <LogoCol xs={8} sm={2}>
              <Link to="/" onClick={closeOverlay}>
                <Logo compact={compact} />
              </Link>
            </LogoCol>

            <NavCol xs={4} sm={10} fillHeight>
              <MenuIcon
                toggle={() => setOverlayVisible(!overlayVisible)}
                open={overlayVisible}
              />
              <MainNav>
                {mainNav.map(item => (
                  <NavItem
                    key={item.id}
                    active={getRelativeUrl(item.url) === location.pathname}
                  >
                    <Link to={getRelativeUrl(item.url)}>{item.label}</Link>
                    {item.childItems.length > 0 && false && (
                      <ul>
                        {item.childItems.map(child => (
                          <li>
                            <Link to={getRelativeUrl(child.url)}>
                              {child.label}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </NavItem>
                ))}
              </MainNav>
            </NavCol>
          </Row>
        </Container>
      </MainBar>
    </Wrapper>
  )
}

const NavItemPropType = PropTypes.shape({
  id: PropTypes.string,
  label: PropTypes.string,
  url: PropTypes.string
})

Navigation.propTypes = {
  mainNav: PropTypes.arrayOf(NavItemPropType).isRequired,
  secondaryNav: PropTypes.arrayOf(NavItemPropType).isRequired
}

Navigation.defaultProps = {
  mainNav: [],
  secondaryNav: []
}

export default Navigation

const Wrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 20;
`

const TopBar = styled.div`
  position: relative;
  height: ${props => props.theme.topNavHeight}px;
  display: flex;
  align-items: center;
  z-index: 3;

  border-bottom: 1px solid ${props => props.theme.colors.tones.lightest};
  background-color: #fff;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const KBLogo = styled.img`
  width: 165px;
  height: auto;
`

const MainBar = styled.div`
  position: relative;
  background-color: ${props => props.theme.colors.primary};
  height: ${props => props.theme.navHeight - props.theme.topNavHeight}px;
  z-index: 2;

  transform: translateY(${props => (props.compact ? -30 : 0)}px);
  transition: 500ms;

  /** Make container, row fill height **/
  > div,
  > div > div {
    height: 100%;
  }

  > div > div {
    transform: translateY(${props => (props.compact ? 15 : 0)}px);
    transition: 500ms;
  }
`

const MainNav = styled.ul`
  list-style: none;
  text-align: center;
  padding: 0;
  margin: 0;
  height: 100%;
  display: none;

  ${media.lg`
    display: block;
  `}
`

const NavCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: ${props => (props.fillHeight ? '100%' : 'auto')};
`

const LogoCol = styled(Col)`
  display: flex;
  align-items: center;
`

const NavItem = styled.li`
  display: inline-flex;
  position: relative;
  height: 100%;
  padding: 0 15px;
  align-items: center;

  &:last-child {
    padding-right: 0;
  }

  ${media.xl`
    padding: 0 17px;
  `}

  a {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    font-weight: ${props => props.theme.fontWeights.bold};
    transition: all 400ms;
  }

  /* &:after {
    display: block;
    content: '';
    position: absolute;
    transition: all 700ms;

    bottom: 13px;
    width: 15px;
    height: 1px;
    left: 50%;
    margin-left: -7.5px;

    background-color: ${props => props.theme.colors.primary};

    opacity: ${props => (props.active ? 1 : 0)};
    transform: scale(${props => (props.active ? 1 : 0)}, 1);
  } */
`

const MenuIcon = styled(MenuIconComponent)`
  display: flex;
  margin-right: -10px;

  ${media.lg`
    display: none;
  `}
`

const Overlay = styled.div`
  position: fixed;
  top: ${props => props.theme.navHeight}px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  padding: 20px;

  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  background-color: #fff;
  color: #fff;
  opacity: ${props => (props.visible ? 1 : 0)};
  transform: translateY(${props => (props.visible ? 0 : -50)}%);
  z-index: 1;
  pointer-events: ${props => (props.visible ? 'auto' : 'none')};

  transition: all 800ms;
`

const OverlayNav = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  > div {
    transform: translateX(${props => (props.visible ? 0 : -50)}%);
    opacity: ${props => (props.visible ? 1 : 0)};
    transition: all 400ms;
  }
`

const OverlayNavItem = styled.div`
  font-size: 22px;
  line-height: 40px;
  margin-bottom: 20px;

  && {
    transition-delay: ${props => 400 + props.index * 200}ms;
  }

  a {
    font-weight: 300;
    color: ${props =>
      props.active
        ? props.theme.colors.primary
        : props.theme.colors.tones.dark};
  }
`
