import React from 'react'
import styled from 'styled-components'

import responsiveSpacing from 'ui/styles/responsiveSpacing'
import PaddedContent from 'ui/components/generic/PaddedContent'

import AccordionItem from './AccordionItem'

export default ({ items }) => {
  return (
    <Wrapper>
      <PaddedContent size="large">
        <Content>
          {items.map((item, index) => (
            <AccordionItem {...item} key={index} />
          ))}
        </Content>
      </PaddedContent>
    </Wrapper>
  )
}

export const query = `
  items {
    header
    body
    links {
      link
      title
    }
  }
`

const Wrapper = styled.div`
  padding: 50px 0;
  background-color: ${props => props.theme.colors.tones.lightest};
`

const Content = styled.div`
  background-color: #fff;
  ${responsiveSpacing(2)}
  border-radius: ${props => props.theme.borderRadius}px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.02);
`
