import React, { PureComponent } from 'react'
import styled, { keyframes } from 'styled-components'

const entry = keyframes`
  0%{
    transform: translateX(50%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  width: 50px;
  height: 40px;
  transition: all 200ms;
  cursor: pointer;
`

const Line = styled.div`
  height: 1px;
  background-color: #fff;
  animation: ${entry} 500ms backwards;
  transition: all 500ms;
`

const Line1 = styled(Line)`
  transform: translateY(${props => (props.open ? 10 : 0)}px);
`

const Line2 = styled(Line)`
  animation-delay: 200ms;
  transform: scale(${props => (props.open ? 0 : 1)}, 1);
  opacity: ${props => (props.open ? 0 : 1)};
`

const Line3 = styled(Line)`
  animation-delay: 400ms;
  transform: translateY(${props => (props.open ? -9 : 0)}px);
`

export default class MenuIcon extends PureComponent {
  render() {
    const { open, toggle, ...rest } = this.props
    return (
      <Wrapper open={open} onClick={() => toggle()} {...rest}>
        <Line1 open={open} />
        <Line2 open={open} />
        <Line3 open={open} />
      </Wrapper>
    )
  }
}
