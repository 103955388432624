import React, { Component } from 'react'
import styled from 'styled-components'
import PaddedContent from 'ui/components/generic/PaddedContent'
import responsiveSpacing from 'ui/styles/responsiveSpacing'
import { Text } from 'ui/styles/utils'

class Quote extends Component {
  render() {
    const { quote, name, title } = this.props
    return (
      <Wrapper>
        <PaddedContent size="large">
          <BlockQuote dangerouslySetInnerHTML={{ __html: quote }} />
          <Name>{name}</Name>
          <Title>{title}</Title>
        </PaddedContent>
      </Wrapper>
    )
  }
}

export const query = `
  quote
  name
  title
`

export default Quote

const Wrapper = styled.div`
  padding: 50px 0;
  text-align: center;
`

const BlockQuote = styled(Text).attrs({ as: 'blockquote' })`
  font-style: italic;
  font-weight: ${props => props.theme.fontWeights.light};
  ${responsiveSpacing(1)}
  margin: 0;

  p {
    margin-bottom: 0;
  }
`

const Name = styled(Text).attrs({ size: 'md' })`
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.colors.primary};
`

const Title = styled(Text).attrs({ size: 'xs' })``
