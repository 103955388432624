import React from 'react'
import styled from 'styled-components'

const getDegrees = (direction) => {
  switch (direction) {
    case 'up':
      return -90
    case 'left':
      return 180
    case 'down':
      return 90
    default:
      return 0
  }
}

const Svg = styled.svg`
  width: 10px;
  height: auto;

  transform: rotate(${props => getDegrees(props.direction)}deg);

  path {
    stroke: ${props => props.color || '#fff'};
  }
`

export default props => (
  <Svg
    viewBox="0 0 10 21"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M.192 1.183l9.75 9.261M.192 19.817l9.75-9.261"
      fill="none" fillRule="evenodd" strokeLinecap="square" />
  </Svg>
)