import React from 'react'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { Link as RouterLink } from 'react-router-dom'

import getRelativeUrl from 'utils/getRelativeUrl'
import theme from 'ui/styles/theme'
import responsiveSpacing from 'ui/styles/responsiveSpacing'
import { Text } from 'ui/styles/utils'
import IconChevron from 'ui/components/generic/IconChevron'
import PaddedContent from 'ui/components/generic/PaddedContent'

export default ({ links }) => {
  return (
    <Wrapper>
      <PaddedContent size="large">
        {links.map(({ header, body, link, image, links: subLinks }) => (
          <Link>
            {image && <Image src={image.sourceUrl} />}
            <Content hasImage={!!image}>
              <Header>{header}</Header>
              <Body dangerouslySetInnerHTML={{ __html: body }} />
              <Text size="sm">
                {subLinks &&
                  subLinks.map(subLink => (
                    <ReadMore to={getRelativeUrl(subLink.link)}>
                      <IconChevron color={theme.colors.primary} />
                      <span
                        dangerouslySetInnerHTML={{ __html: subLink.title }}
                      />
                    </ReadMore>
                  ))}
              </Text>
            </Content>
          </Link>
        ))}
      </PaddedContent>
    </Wrapper>
  )
}

export const query = `
  layout
  links {
    header
    body
    links {
      link
      title
    }
    image {
      sourceUrl
    }
  }
`

const Wrapper = styled.div`
  padding: 50px 0;
  background-color: ${props => props.theme.colors.tones.lightest};
`

const Link = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  background-color: #fff;
  padding: 30px;
  /* border-top: 5px solid ${props => props.theme.colors.primary}; */
  ${responsiveSpacing(2)}
  border-radius: ${props => props.theme.borderRadius}px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.02);
`

const Image = styled.img`
  display: block;
  height: auto;
  max-width: 100%;
  margin-bottom: 20px;

  ${media.sm`
    max-width: calc(40% - 30px);
    margin-right: 30px;
    margin-bottom: 0;
  `}
`

const Content = styled.div`
  width: 100%;

  ${media.sm`
    width: ${props => (props.hasImage ? '60' : 100)}%;
  `}
`

const Header = styled(Text).attrs({ as: 'h2', size: 'md' })`
  ${responsiveSpacing(1)}
`

const Body = styled(Text).attrs({ size: 'sm' })``

const ReadMore = styled(RouterLink).attrs()`
  display: flex;
  align-items: center;
  font-weight: ${props => props.theme.fontWeights.bold};
  padding: 5px 0;

  svg {
    display: block;
    width: 6px;
    height: auto;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 20px;
  }
`
