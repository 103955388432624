import { Component } from 'react'
import { connect } from 'react-redux'

import { setDimensions } from '.'

class MediaProvider extends Component {
  componentDidMount() {
    // set initial dimensions on mount
    this.setDimensions()

    // update when window resizes
    window.addEventListener('resize', this.setDimensions)
  }

  componentWillUnmount() {
    // clean up
    window.removeEventListener('resize', this.setDimensions)
  }

  setDimensions = () => {
    this.props.setDimensions(window.innerWidth, window.innerHeight)
  }

  render() {
    return this.props.children
  }
}

export default connect(null, { setDimensions })(MediaProvider)