import React from 'react'
import styled from 'styled-components'
import { Container } from 'styled-bootstrap-grid'
import { Link } from 'react-router-dom'

import theme from 'ui/styles/theme'
import getRelativeUrl from 'utils/getRelativeUrl'
import IconChevron from 'ui/components/generic/IconChevron'

export default ({ post }) => {
  const nodes = getNodes(post)
  return (
    <BreadCrumb>
      <Container fluid>
        <Entries>
          {nodes.map(node => (
            <Entry>
              <Seperator />
              {node.link ? (
                <Link
                  dangerouslySetInnerHTML={{ __html: node.title }}
                  to={getRelativeUrl(node.link)}
                />
              ) : (
                <span dangerouslySetInnerHTML={{ __html: node.title }} />
              )}
            </Entry>
          ))}
        </Entries>
      </Container>
    </BreadCrumb>
  )
}

const getNodes = post => {
  const nodes = [{ title: 'Forside', link: '/', id: 'front-page' }]

  switch (post.__typename) {
    case 'Post':
      nodes.push({ title: 'Nyheder', link: '/nyheder' })
      break
    default:
      if (post.ancestors) {
        post.ancestors
          .concat()
          .reverse()
          .forEach(node => nodes.push(node))
      }
      break
  }

  nodes.push({ title: post.title, link: '' })

  return nodes
}

const BreadCrumb = styled.div`
  background-color: ${props => props.theme.colors.tones.lighter};

  font-size: 14px;
`

const Seperator = styled(IconChevron).attrs({
  direction: 'right',
  color: theme.colors.primary
})`
  width: 4px;
  height: auto;
  margin: 0 15px;
`

const Entries = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
`

const Entry = styled.div`
  display: inline-flex;
  align-items: center;

  &:first-child {
    ${Seperator} {
      display: none;
      margin-left: 0;
    }
  }
`
