import React, { Component } from 'react'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import styled from 'styled-components'

const StyledContainer = styled(Container)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${props => props.z || 9999};
  pointer-events: none;
  opacity: ${props => props.visible ? 1 : 0};
  transition: all 500ms;
`

const StyledRow = styled(Row)`
  height: 100%;
`

const WhiteCol = styled.div`
  background: ${props => props.theme.colors.primary};
  opacity: 0.25;
  height: 100%;
`

export default class GridView extends Component {
  state = { visible: false }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown = (e) => {
    if (e.ctrlKey && e.key === 'g') {
      this.setState(state => ({ visible: !state.visible }))
    }
  }

  render() {
    const { z } = this.props
    const { visible } = this.state

    return (
      <StyledContainer
        fluid
        z={z}
        visible={visible}
      >
        <StyledRow>
          <Col col="1">
            <WhiteCol />
          </Col>
          <Col col="1">
            <WhiteCol />
          </Col>
          <Col col="1">
            <WhiteCol />
          </Col>
          <Col col="1">
            <WhiteCol />
          </Col>
          <Col col="1">
            <WhiteCol />
          </Col>
          <Col col="1">
            <WhiteCol />
          </Col>
          <Col col="1">
            <WhiteCol />
          </Col>
          <Col col="1">
            <WhiteCol />
          </Col>
          <Col col="1">
            <WhiteCol />
          </Col>
          <Col col="1">
            <WhiteCol />
          </Col>
          <Col col="1">
            <WhiteCol />
          </Col>
          <Col col="1">
            <WhiteCol />
          </Col>
        </StyledRow>
      </StyledContainer>
    )
  }
}
