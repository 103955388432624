import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Row, Col, media } from 'styled-bootstrap-grid'
import { Text } from 'ui/styles/utils'
import { PoseGroup } from 'react-pose'

import { Error, ErrorMessage } from 'ui/components/modules/Form'

const Wrapper = styled.div`
`

const Label = styled(Text).attrs({ size: 'xs' })`
  color: ${props => props.theme.colors.tones.light};
  margin-top: 10px;
`

const Field = styled(Col)`
  margin-bottom: 20px;

  ${media.sm`
    margin-bottom: 0;
  `}
`

export default class Name extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    format: PropTypes.string,
    first_default: PropTypes.string,
    middle_default: PropTypes.string,
    last_default: PropTypes.string,
    simple_default: PropTypes.string,
    first_placeholder: PropTypes.string,
    middle_placeholder: PropTypes.string,
    last_placeholder: PropTypes.string,
    simple_placeholder: PropTypes.string,
    sublabel_hide: PropTypes.string
  }

  static defaultProps = {
    value: ''
  }

  constructor(props) {
    super(props)

    const {
      value,
      first_default,
      middle_default,
      last_default,
      simple_default
    } = props

    this.state = {
      first: value.first || first_default,
      middle: value.middle || middle_default,
      last: value.last || last_default,
      simple: value || simple_default
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { onChange, id } = this.props
    if (
      this.state.first !== prevState.first ||
      this.state.middle !== prevState.middle ||
      this.state.last !== prevState.last ||
      this.state.simple !== prevState.simple
    ) {
      onChange(id, this.getValue())
    }
  }

  getValue() {
    const { format } = this.props
    const { first, last, middle, simple } = this.state

    switch (format) {
      case 'first-last':
        return {
          first,
          last
        }
      case 'first-middle-last':
        return {
          first,
          middle,
          last
        }
      default:
        return simple
    }
  }

  render () {
    const {
      format,
      value,
      simple_placeholder,
      first_placeholder,
      middle_placeholder,
      last_placeholder,
      sublabel_hide,
      error
    } = this.props

    const colSpan = format === 'first-middle-last' ? 4 : 6

    return (
      <Wrapper>
        { format === 'simple' &&
          <div>
            <input
              type="text"
              onChange={(e) => this.setState({ simple: e.target.value })}
              value={value}
              placeholder={simple_placeholder}
            />
          </div>
        }
        { format !== 'simple' && (
          <Row>
            <Field sm={colSpan}>
              <input
                type="text"
                onChange={(e) => this.setState({ first: e.target.value })}
                value={value.first}
                placeholder={first_placeholder}
              />
              <PoseGroup withParent={false}>
                { error && error.first &&
                  <Error key="error">
                    <ErrorMessage>{error.first}</ErrorMessage>
                  </Error>
                }
              </PoseGroup>
              {!sublabel_hide &&
                <Label>First</Label>
              }

            </Field>
            { format === 'first-middle-last' &&
              <Field sm={colSpan}>
                <input
                  type="text"
                  onChange={(e) => this.setState({ middle: e.target.value })}
                  value={value.middle}
                  placeholder={middle_placeholder}
                />
                <PoseGroup withParent={false}>
                  {error && error.middle &&
                    <Error key="error">
                      <ErrorMessage>{error.middle}</ErrorMessage>
                    </Error>
                  }
                </PoseGroup>
                {!sublabel_hide &&
                  <Label>Middle</Label>
                }
              </Field>
            }
            <Field sm={colSpan}>
              <input
                type="text"
                onChange={(e) => this.setState({ last: e.target.value })}
                value={value.last}
                placeholder={last_placeholder}
              />
              <PoseGroup withParent={false}>
                {error && error.last &&
                  <Error key="error">
                    <ErrorMessage>{error.last}</ErrorMessage>
                  </Error>
                }
              </PoseGroup>
              {!sublabel_hide &&
                <Label>Last</Label>
              }
            </Field>
          </Row>
          )
        }
      </Wrapper>
    )
  }
}