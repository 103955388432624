import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

const Wrapper = styled.div`
  height: 40px;
  min-width: 150px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border-radius: ${props => props.theme.borderRadius}px;
  border: 1px solid ${props => props.color ? props.theme.colors[props.color] : '#fff'};
  background-color: ${props => props.color ? props.theme.colors[props.color] : '#fff'};

  text-transform: uppercase;
  font-size: 12px;
  font-family: ${props => props.theme.monoFont};

  transition: all 400ms;

  & + a {
    margin-left: 10px !important;
  }

  &, &:active, &:visited {
    color:  ${props => props.color ? '#fff' : props.theme.colors.primary};
    text-decoration: none;
  }

  &:hover {
    background-color: transparent;
    color: ${props => props.color ? props.theme.colors[props.color] : '#fff'};
  }

  ${props => props.hollow && css`
    &, &:active, &:visited {
      background-color: transparent;
      color: #fff;
      border-color: #fff;
    }

    &:hover {
      background-color: #fff;
      color: ${props.color ? props.theme.colors[props.color] : props.theme.colors.primary};
    }
  `}
`

class Button extends Component {

  static defaultProps = {
    hollow: false,
    component: Link
  }

  render() {
    const { component, ...rest } = this.props
    return (
      <Wrapper as={component} {...rest} />
    );
  }
}

export default Button;