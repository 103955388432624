import React, { Component } from 'react'
import styled from 'styled-components'

import PaddedContent from 'ui/components/generic/PaddedContent'
import { Text } from 'ui/styles/utils'

class Image extends Component {
  render() {
    const {
      image: { sourceUrl, altText },
      caption,
      size
    } = this.props

    return (
      <Wrapper>
        <PaddedContent size={size}>
          <img src={sourceUrl} alt={caption || altText} />
          {caption && <Caption dangerouslySetInnerHTML={{ __html: caption }} />}
        </PaddedContent>
      </Wrapper>
    )
  }
}

export default Image

export const query = `
  image {
    sourceUrl
    altText
  }
  caption
  size
`

const Wrapper = styled.div`
  padding: 50px 0;

  img {
    max-width: 100%;
  }
`

const Caption = styled(Text).attrs({ size: 'xs' })`
  color: ${props => props.theme.colors.primary};
  font-weight: ${props => props.theme.fontWeights.bold};
  margin-top: 10px;
`
