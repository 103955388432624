import React from 'react'

const getRows = size => {
  switch (size) {
    case 'small':
      return 3
    case 'large':
      return 10
    default:
      return 5
  }
}

export default ({size, id, onChange, css, placeholder }) => (
  <textarea
    className={css}
    placeholder={placeholder}
    rows={getRows(size)}
    onChange={(e) => onChange(id, e.target.value)}
  />
)