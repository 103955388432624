import React from 'react'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

const Wrapper = styled.div`
  height: ${props => props.distance * 50}px;

  ${media.xs`
    height: ${props => props.distance * 20}px;
  `}
`

export default ({ distance }) => (<Wrapper distance={distance} />)

export const query = `
  distance
`
