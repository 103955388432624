import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'

import Loading from 'ui/components/generic/Loading'
import Date from 'ui/components/generic/Date'
import ArrowLink from 'ui/components/generic/ArrowLink'
import { Text } from 'ui/styles/utils'
import getRelativeUrl from 'utils/getRelativeUrl'
import Button from 'ui/components/generic/Button'
import usePosts from 'containers/usePosts'

export default () => {
  const { data: posts, loading } = usePosts(2)

  if (loading) return <Loading />

  return (
    <Wrapper>
      <Container fluid>
        <Row>
          {posts &&
            posts.map(post => (
              <Col sm={6} key={post.id}>
                <NewsPost>
                  <div>
                    <DateWrapper>
                      <Date date={post.date} />
                    </DateWrapper>
                    <Text
                      as="h2"
                      dangerouslySetInnerHTML={{ __html: post.title }}
                    />
                    <Text
                      dangerouslySetInnerHTML={{ __html: post.excerpt }}
                      size="sm"
                    />
                  </div>
                  <Button color="primary" to={getRelativeUrl(post.link)}>
                    Læs mere
                  </Button>
                </NewsPost>
              </Col>
            ))}
        </Row>
        <Link>
          <ArrowLink to="/nyheder">Se flere nyheder</ArrowLink>
        </Link>
      </Container>
    </Wrapper>
  )
}

export const query = ``

const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.tones.lightest};
  padding: 50px 0;
`

const Link = styled.div`
  text-align: right;
`

const NewsPost = styled.div`
  padding: 30px;
  background-color: #fff;
  margin-bottom: 30px;

  ${media.sm`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    height: 100%;
    margin-bottom: 0;

  `}
`

const DateWrapper = styled(Text).attrs({ size: 'sm', weight: 'light' })`
  color: ${props => props.theme.colors.primary};
  margin-bottom: 15px;
`
