import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.svg`
  transform-origin: center;
  transform: rotate(${props => props.direction === 'right' ? 180 : 0}deg);
`

export default ({ color, direction, ...rest }) => (
  <Wrapper direction={direction} {...rest} width="23" height="14" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.044 13.052L.776 6.837 7.044.623M.908 6.89h21.875" stroke={color || '#373737'}
      fill="none" fillRule="evenodd" />
  </Wrapper>
);