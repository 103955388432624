import { gql, useQuery } from '@apollo/client'

export default (
  limit = 10,
  search = null,
  offset = null,
  orderBy = 'DATE',
  order = 'DESC'
) => {
  const query = gql`
    query(
      $offset: Int
      $size: Int
      $orderBy: PostObjectsConnectionOrderbyEnum!
      $order: OrderEnum!
      $search: String
    ) {
      posts(
        where: {
          orderby: { field: $orderBy, order: $order }
          search: $search
          offsetPagination: { offset: $offset, size: $size }
        }
      ) {
        nodes {
          id
          title
          date
          excerpt
          link
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          offsetPagination {
            hasMore
            hasPrevious
            total
          }
        }
      }
    }
  `

  const variables = {
    orderBy,
    order,
    search,
    offset,
    size: limit
  }

  const { data, loading, error } = useQuery(query, { variables })

  if (error) {
    console.error(error)
  }

  return {
    data: loading ? [] : data?.posts?.nodes,
    loading,
    pageInfo: data?.posts?.pageInfo
  }
}
