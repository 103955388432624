import React, { Component } from 'react'
import styled from 'styled-components'

import { Text } from 'ui/styles/utils'
import PaddedContent from 'ui/components/generic/PaddedContent'
import responsiveSpacing from 'ui/styles/responsiveSpacing'
import BreadCrumb from 'ui/components/BreadCrumb'

class index extends Component {
  render() {
    const { post } = this.props

    if (!post.header) return null

    const { image, introduction } = post?.header

    return (
      <Wrapper image={image?.sourceUrl}>
        <BreadCrumb post={post} />
        <Inner>
          <PaddedContent size="large">
            <Header dangerouslySetInnerHTML={{ __html: post.title }} />
            <Introduction dangerouslySetInnerHTML={{ __html: introduction }} />
          </PaddedContent>
        </Inner>
      </Wrapper>
    )
  }
}

export const query = `
  introduction
`

export default index

const Wrapper = styled.div`
  background-color: #fff;
`

const Inner = styled.div`
  ${responsiveSpacing(2, 'padding-top')}
  ${responsiveSpacing(2, 'padding-bottom')}
`

const Header = styled(Text).attrs({ size: 'xl', as: 'h1' })`
  text-align: center;
`

const Introduction = styled(Text).attrs({ size: 'md' })`
  margin-top: 30px;
  color: ${props => props.theme.colors.tertiary};
  text-align: center;
`.withComponent('div')
