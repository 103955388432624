import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

import Arrow from 'ui/components/generic/Arrow'

const ArrowWrapper = styled.span`
  transition: all 400ms;
`

const Text = styled.span`
  display: inline-block;
`

const Wrapper = styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;

  &:hover ${ArrowWrapper} {
    transform: translateX(
      ${props => (props.direction === 'left' ? -10 : 10)}px
    );
    opacity: 0.3;
  }

  ${props =>
    props.disabled &&
    css`
      &,
      ${ArrowWrapper} {
        cursor: default;
        transform: none;
        opacity: 0.2;
      }
    `}

  ${Text} {
    margin-right: ${props => (props.direction === 'left' ? 0 : 20)}px;
    margin-left: ${props => (props.direction === 'left' ? 20 : 0)}px;
  }
`

class ArrowLink extends Component {
  render() {
    const { children, direction, arrowColor, ...rest } = this.props
    return (
      <Wrapper {...rest} direction={direction}>
        {direction === 'left' && (
          <ArrowWrapper>
            <Arrow direction="left" color={arrowColor} />
          </ArrowWrapper>
        )}
        <Text>{children}</Text>
        {direction !== 'left' && (
          <ArrowWrapper>
            <Arrow direction="right" color={arrowColor} />
          </ArrowWrapper>
        )}
      </Wrapper>
    )
  }
}

export default ArrowLink
