import React, { Component } from 'react'
import styled from 'styled-components'

import PaddedContent from 'ui/components/generic/PaddedContent'

class Embed extends Component {
  render() {
    const { embed } = this.props

    return (
      <Wrapper>
        <PaddedContent>
          <EmbedContainer dangerouslySetInnerHTML={{ __html: embed }} />
        </PaddedContent>
      </Wrapper>
    )
  }
}

export const query = `
  embed
`

export default Embed

const Wrapper = styled.div`
  padding: 50px 0;
`

const EmbedContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
