export default {
  displayFont: '"Roboto", sans-serif',
  bodyFont: '"Roboto", Helvetica, serif',
  fontSizes: {
    xxl: {
      xs: {
        fs: 100,
        lh: 119
      },
      sm: {
        fs: 100,
        lh: 119
      },
      md: {
        fs: 100,
        lh: 119
      },
      lg: {
        fs: 100,
        lh: 119
      },
      xl: {
        fs: 100,
        lh: 119
      }
    },
    xl: {
      xs: {
        fs: 32,
        lh: 40
      },
      sm: {
        fs: 32,
        lh: 40
      },
      md: {
        fs: 60,
        lh: 80
      },
      lg: {
        fs: 60,
        lh: 80
      },
      xl: {
        fs: 60,
        lh: 80
      }
    },
    lg: {
      xs: {
        fs: 24,
        lh: 32
      },
      sm: {
        fs: 24,
        lh: 32
      },
      md: {
        fs: 32,
        lh: 38
      },
      lg: {
        fs: 32,
        lh: 38
      },
      xl: {
        fs: 32,
        lh: 38
      }
    },
    md: {
      xs: {
        fs: 18,
        lh: 26
      },
      sm: {
        fs: 18,
        lh: 26
      },
      md: {
        fs: 24,
        lh: 30
      },
      lg: {
        fs: 24,
        lh: 30
      },
      xl: {
        fs: 24,
        lh: 30
      }
    },
    sm: {
      xs: {
        fs: 14,
        lh: 20
      },
      sm: {
        fs: 14,
        lh: 20
      },
      md: {
        fs: 18,
        lh: 26
      },
      lg: {
        fs: 18,
        lh: 26
      },
      xl: {
        fs: 18,
        lh: 26
      }
    },
    xs: {
      xs: {
        fs: 12,
        lh: 16
      },
      sm: {
        fs: 12,
        lh: 16
      },
      md: {
        fs: 14,
        lh: 20
      },
      lg: {
        fs: 14,
        lh: 20
      },
      xl: {
        fs: 14,
        lh: 20
      }
    },
    xxs: {
      xs: {
        fs: 12,
        lh: 16
      },
      sm: {
        fs: 12,
        lh: 16
      },
      md: {
        fs: 12,
        lh: 16
      },
      lg: {
        fs: 12,
        lh: 16
      },
      xl: {
        fs: 12,
        lh: 16
      }
    }
  },
  fontWeights: {
    thin: 100,
    light: 200,
    normal: 400,
    bold: 600,
    black: 700
  },
  spacing: {
    xs: 5,
    sm: 15,
    md: 15,
    lg: 20,
    xl: 20
  },
  colors: {
    tones: {
      lightest: '#FAFAFA',
      lighter: '#E6E6E6',
      light: '#D2D2D2',
      medium: '#828282',
      dark: '#646464',
      darker: '#464646',
      darkest: '#282828'
    },
    primary: '#2F3D57',
    primaryLight: '#596479',
    primaryLighter: '#828A9A',
    primaryDark: '#192741',
    primaryDarker: '#141F34',
    secondary: '#D8232A',
    secondaryLight: '#DF4B50',
    secondaryLighter: '#E67377',
    secondaryDark: '#B11D23',
    secondaryDarker: '#8A171B',
    tertiary: '#68B9D8',
    tertiaryLight: '#75BFDB',
    tertiaryLighter: '#83C5DF',
    tertiaryDark: '#5FA9C5',
    tertiaryDarker: '#5698B1',
    quaternary: '#C4B6A7',
    quaternaryLight: '#CEC3B7',
    quaternaryLighter: '#D9D0C7',
    quaternaryDark: '#A19589',
    quaternaryDarker: '#7D746B',
    quinary: '#7CB69D',
    quinaryLight: '#99C6B3',
    quinaryLighter: '#B6D6C8',
    quinaryDark: '#60A688',
    quinaryDarker: '#4F8870',
    background: '#EEEEEA',
    text: '#4A4A4A',
    textLight: '#646464',
    danger: '#801D15'
  },
  borderRadius: 4,
  topNavHeight: 33,
  navHeight: 80 + 33,
  grid: {
    breakpoints: {
      // defaults below
      giant: 1200,
      xl: 1200,
      desktop: 992,
      lg: 992,
      tablet: 768,
      md: 768,
      phone: 576,
      sm: 576,
      smaller: 575,
      xs: 575
    },
    row: {
      padding: 25 // default 15
    },
    col: {
      padding: 25 // default 15
    },
    container: {
      padding: 50, // default 15
      paddingXs: 20
    }
  }
}
