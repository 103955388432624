import React, { Component } from 'react'
import styled from 'styled-components'

import { BodyText as Text } from 'ui/styles/utils'
import PaddedContent from 'ui/components/generic/PaddedContent'

class BodyText extends Component {
  render() {
    const { body } = this.props

    return (
      <Wrapper>
        <PaddedContent size="large">
          <Text dangerouslySetInnerHTML={{ __html: body }} size="sm" />
        </PaddedContent>
      </Wrapper>
    )
  }
}

export const query = `
  body
`

const Wrapper = styled.div``

export default BodyText
