import React from 'react'

export default ({ id, onChange, placeholder, css }) =>  (
  <input
    placeholder={placeholder}
    className={css}
    type="email"
    onChange={(e) => onChange(id, e.target.value)}

  />
)