import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import styled, { keyframes } from 'styled-components'

const scrollTime = 250
const animationDuration = 500

const enter = keyframes`
  from {
    opacity: 0;
    transform: translateX(30px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`

const exit = keyframes`
  from {
    opacity: 1;
    transform: translateX(0px);
  }
  to {
    opacity: 0;
    transform: translateX(-30px);
  }
`

const Wrapper = styled.div`
  position: relative;
  z-index: 10;
  height: ${props => (props.transitioning ? '10000000px' : 'auto')};
  background-color: #fff;
  overflow: hidden;

  .fade-enter,
  .fade-exit {
    background-color: #fff;
  }

  .fade-enter {
    position: relative;
    opacity: 0;
    z-index: 1;
    height: 100000px;
  }

  .fade-enter.fade-enter-active {
    animation: ${enter} ${animationDuration}ms both;
    animation-delay: ${animationDuration + scrollTime}ms;
  }

  .fade-exit {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    min-height: 100vh;
    z-index: 2;

    /* Remove top margin when transitioning....... 🤔 */
    /* > div > div {
      margin-top: 0;
    } */
  }

  .fade-exit.fade-exit-active {
    animation: ${exit} ${animationDuration}ms both;
    animation-delay: 0ms;
  }
`

class AnimatedRoutes extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    pathname: PropTypes.string.isRequired,
    getScrollTarget: PropTypes.func
  }

  static defaultProps = {
    getScrollTarget: undefined
  }

  state = {
    transitioning: false
  }

  render() {
    const { children, pathname } = this.props
    const { transitioning } = this.state

    return (
      <Wrapper transitioning={transitioning}>
        <TransitionGroup>
          <CSSTransition
            key={pathname}
            classNames="fade"
            timeout={scrollTime + animationDuration * 2}
          >
            {children}
          </CSSTransition>
        </TransitionGroup>
      </Wrapper>
    )
  }
}

export default AnimatedRoutes
