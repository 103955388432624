import { gql, useQuery } from '@apollo/client'

export default () => {
  const { data, loading, error } = useQuery(gql`
    query SiteMeta {
      siteMeta {
        frontPageSlug
        notFoundSlug
        homeUrl
        gtmId
      }
    }
  `)

  if (error) {
    console.error('Error fetching site meta', error)
  }

  return { data, loading, error }
}
