import styled, { css } from 'styled-components'

import responsiveText from './responsiveText'

export const Relative = styled.div`
  position: relative;
`

export const Margin = styled.div``

export const Text = styled.div`
  /**
  * Font size
  **/
  ${props => responsiveText(props.size)}

  /**
  * Font-weight
  **/
  ${props =>
    props.weight &&
    css`
      font-weight: ${props =>
        props.theme.fontWeights[props.weight] || 'normal'};
    `}

  /**
  * Color
  **/
  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `}

  /**
  * Margin
  **/
  ${props =>
    props.mt &&
    css`
      margin-top: ${props.mt}px;
    `}

  ${props =>
    props.mb &&
    css`
      margin-bottom: ${props.mb}px;
    `}

  ${props =>
    props.ml &&
    css`
      margin-left: ${props.ml}px;
    `}

  ${props =>
    props.mr &&
    css`
      margin-right: ${props.mr}px;
    `}

  /**
  * Align
  **/
  ${props =>
    props.align &&
    css`
      text-align: ${props.align};
    `}
`

export const BodyText = styled.div`
  ${responsiveText('sm')}

  h2, h3, h4, h5 {
    ${responsiveText('sm')}
  }
`
