import React, { Component } from 'react'
import styled from 'styled-components'

import Module from 'ui/components/Module'
import Hero from 'ui/components/modules/Hero'
import FeaturedLinks from 'ui/components/modules/FeaturedLinks'
import Link from 'ui/components/modules/Link'
import LinkList from 'ui/components/modules/LinkList'
import Accordion from 'ui/components/modules/Accordion'
import LatestNews from 'ui/components/modules/LatestNews'
import NewsArchive from 'ui/components/modules/NewsArchive'
import Header from 'ui/components/modules/Header'
import BodyText from 'ui/components/modules/BodyText'
import Quote from 'ui/components/modules/Quote'
import Gallery from 'ui/components/modules/Gallery'
import ImageAndText from 'ui/components/modules/ImageAndText'
import Image from 'ui/components/modules/Image'
import Embed from 'ui/components/modules/Embed'
import Form from 'ui/components/modules/Form'
import TextBox from 'ui/components/modules/TextBox'
import Distance from 'ui/components/modules/Distance'

const Wrapper = styled.div`
  background-color: #fff;
`

const Missing = styled.div`
  padding: 30px;
  background-color: ${props => props.theme.colors.tones.light};
  color: ${props => props.theme.colors.tones.dark};
  font-size: 18px;
`

export default class Modules extends Component {
  getModules() {
    const { modules, post, type } = this.props
    return modules.map((module, index) => {
      const key = index + '-' + module.layout
      switch (module.layout) {
        case 'hero':
          return <Hero {...module} post={post} type={type} />
        case 'header':
          return <Header {...module} post={post} type={type} />
        case 'featuredLinks':
          return <FeaturedLinks {...module} />
        case 'link':
          return <Link {...module} />
        case 'linkList':
          return <LinkList {...module} />
        case 'accordion':
          return <Accordion {...module} />
        case 'latestNews':
          return <LatestNews {...module} />
        case 'newsArchive':
          return <NewsArchive {...module} />
        case 'bodyText':
          return <BodyText {...module} />
        case 'quote':
          return <Quote {...module} />
        case 'gallery':
          return <Gallery {...module} />
        case 'imageAndText':
          return <ImageAndText {...module} />
        case 'image':
          return <Image {...module} />
        case 'embed':
          return <Embed {...module} />
        case 'form':
          return <Form {...module} />
        case 'textBox':
          return <TextBox {...module} />
        case 'distance':
          return <Distance {...module} />
        default:
          return (
            <Missing key={key}>
              Missing implementation of {module.layout}
            </Missing>
          )
      }
    })
  }

  render() {
    const { modules } = this.props
    const renderedModules = this.getModules()
    return (
      <Wrapper>
        {renderedModules.map((module, index) => (
          <Module
            key={index}
            index={index}
            type={modules[index].layout}
            nextType={modules[index + 1] && modules[index + 1].layout}
            count={modules.length}
          >
            {module}
          </Module>
        ))}
      </Wrapper>
    )
  }
}
