import React, { Component } from 'react'
import styled from 'styled-components'

import Spinner from 'ui/components/generic/Spinner'
import IconPlay from 'ui/components/generic/IconPlay'

const Wrapper = styled.div`
  position: relative;
  width: 80px;
  height: 80px;

  background-color: ${props => props.theme.colors.primary};
  border-radius: 50%;
  cursor: pointer;

  transition: all 400ms;

  svg {
    transform: translateX(16%); /* make visually centered */

    transition: all 400ms;
  }

  &:hover {
    background-color: ${props => props.theme.colors.secondary};

    svg {
      transform: translateX(16%) scale(1.1);
    }
  }
`

const Content = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: ${props => props.visible ? 1 : 0};
  transition: all 600ms;
`

const Play = styled(Content)`
  transform: rotate(${props => props.visible ? 0 : 180}deg);
`

class PlayButton extends Component {
  render() {
    const { loading, ...rest } = this.props

    return (
      <Wrapper { ...rest }>
        <Play visible={!loading}>
          <IconPlay />
        </Play>
        <Content visible={loading}>
          <Spinner />
        </Content>
      </Wrapper>
    );
  }
}

export default PlayButton