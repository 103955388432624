import { gql, useQuery } from '@apollo/client'

import * as queries from 'ui/components/modules/queries'

export default uri => {
  const query = gql`
    query Content($uri: ID!) {
      contentNode(id: $uri, idType: URI) {
        __typename
        ... on Post {
          ${commonFields}
        }

        ... on Page {
          ${commonFields}
          template {
            ... on DefaultTemplate {
              templateName
            }
            ... on ForsideTemplate {
              templateName
            }
          }

          # breadcrumb
          ancestors {
            ... on Page {
              id
              title
              link
            }
          }

          # children
          childPages {
            nodes {
              id
              title
              link
            }
          }

          # siblings
          parent {
            __typename
            ... on Page {
              childPages {
                nodes {
                  id
                  title
                  link
                }
              }
            }
          }
        }
      }
    }
  `

  const { loading, error, data } = useQuery(query, { variables: { uri } })

  return {
    data: data?.contentNode && parseData(data.contentNode),
    loading,
    error
  }
}

// join queries from all modules
const modulesQuery = Object.keys(queries)
  .map(
    key => `
  ...on Modules__${key} {
    layout
    ${queries[key]}
  }
`
  )
  .join('')

// Fields queried for all post types
const commonFields = `
  id
  title
  seo
  slug
  header {
    introduction
  }
  modules {
    ${modulesQuery}
  }
`

const parseData = data => ({
  ...data,
  seo: JSON.parse(data.seo)
})
