import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'unfetch/polyfill'
import React from 'react'
import { hydrate, render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ApolloProvider } from '@apollo/client'

import createStore from 'redux/createStore'
import getClient from 'client'
import LanguageProvider from './LanguageProvider'
import App from 'App'

const client = getClient()

const wrap = AppComponent => (
  <ApolloProvider client={client}>
    <Provider store={createStore()}>
      <BrowserRouter>
        <LanguageProvider>
          <AppComponent />
        </LanguageProvider>
      </BrowserRouter>
    </Provider>
  </ApolloProvider>
)

const rootElement = document.getElementById('root')
if (rootElement.hasChildNodes()) {
  hydrate(wrap(App), rootElement)
} else {
  render(wrap(App), rootElement)
}

// Hot reload if possible
if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default
    render(wrap(NextApp), rootElement)
  })
}
