import React, { useState } from 'react'
import styled from 'styled-components'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'

import Loading from 'ui/components/generic/Loading'
import Date from 'ui/components/generic/Date'
import ArrowLink from 'ui/components/generic/ArrowLink'
import theme from 'ui/styles/theme'
import { Text } from 'ui/styles/utils'
import getRelativeUrl from 'utils/getRelativeUrl'
import Button from 'ui/components/generic/Button'
import usePosts from 'containers/usePosts'

const limit = 6

export default () => {
  const [typedSearch, setTypedSearch] = useState('')
  const [search, setSearch] = useState(null)
  const [page, setPage] = useState(0)
  const { data: posts, loading, pageInfo } = usePosts(
    limit,
    search,
    page * limit
  )

  // if (loading) return <Loading />

  let hasMore, hasPrevious, total

  if (pageInfo?.offsetPagination) {
    ;({ hasMore, hasPrevious, total } = pageInfo.offsetPagination)
  }

  return (
    <Wrapper>
      <Container fluid>
        <Search>
          <input
            type="text"
            onChange={e => setTypedSearch(e.target.value)}
            onKeyPress={e => {
              if (e.key === 'Enter') setSearch(typedSearch)
            }}
            placeholder="Søg efter nyheder.."
            value={typedSearch}
          />
          <Button
            component="button"
            color="primary"
            onClick={() => setSearch(typedSearch || null)}
          >
            Søg
          </Button>
        </Search>
        {loading && <Loading />}
        {search && (
          <SearchHeader>
            Viser resultater som indeholder <span>“{search}”</span>
          </SearchHeader>
        )}
        <Row>
          {posts &&
            posts.map(post => (
              <NewsPostWrapper sm={6} key={post.id}>
                <NewsPost>
                  <div>
                    <DateWrapper>
                      <Date date={post.date} />
                    </DateWrapper>
                    <Text
                      as="h2"
                      dangerouslySetInnerHTML={{ __html: post.title }}
                    />
                    <Text
                      dangerouslySetInnerHTML={{ __html: post.excerpt }}
                      size="sm"
                    />
                  </div>
                  <Button color="primary" to={getRelativeUrl(post.link)}>
                    Læs mere
                  </Button>
                </NewsPost>
              </NewsPostWrapper>
            ))}
        </Row>
        {(hasMore || hasPrevious) && (
          <Pagination>
            <ArrowLink
              disabled={!hasPrevious}
              onClick={hasPrevious ? () => setPage(page - 1) : undefined}
              arrowColor={theme.colors.primary}
              direction="left"
            >
              Forrige side
            </ArrowLink>
            <span>
              Side {page + 1} af {Math.ceil(total / limit)}
            </span>
            <ArrowLink
              disabled={!hasMore}
              onClick={hasMore ? () => setPage(page + 1) : undefined}
              arrowColor={theme.colors.primary}
              direction="right"
            >
              Næste side
            </ArrowLink>
          </Pagination>
        )}
      </Container>
    </Wrapper>
  )
}

export const query = ``

const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.tones.lightest};
  padding: 50px 0;
`

const Search = styled.div`
  position: relative;
  padding: 30px;
  background-color: #fff;

  margin-bottom: 20px;

  input {
    border: 1px solid ${props => props.theme.colors.tones.lighter};
    padding-right: 100px;
  }

  button {
    position: absolute;
    top: 50%;
    margin-top: -20px;
    right: 34px;
  }
`

const NewsPostWrapper = styled(Col)`
  margin-bottom: 30px;
`

const NewsPost = styled.div`
  padding: 30px;
  background-color: #fff;

  ${media.sm`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    height: 100%;

  `}
`

const DateWrapper = styled(Text).attrs({ size: 'sm', weight: 'light' })`
  color: ${props => props.theme.colors.primary};
  margin-bottom: 15px;
`

const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #fff;
`

const SearchHeader = styled(Text).attrs({ size: 'sm' })`
  margin-bottom: 20px;

  span {
    color: ${props => props.theme.colors.primary};
    font-weight: 700;
  }
`
