import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { media } from 'styled-bootstrap-grid'

import getRelativeUrl from 'utils/getRelativeUrl'
import PaddedContent from 'ui/components/generic/PaddedContent'
import IconLink from 'ui/components/generic/IconLink'
import IconChevron from 'ui/components/generic/IconChevron'
import { Text } from 'ui/styles/utils'
import responsiveSpacing from 'ui/styles/responsiveSpacing'

export default ({
  type,
  internalLink,
  externalLink,
  header,
  body,
  image,
  metaHeader
}) => {
  return (
    <PaddedContent size="large">
      {metaHeader && (
        <MetaHeader dangerouslySetInnerHTML={{ __html: metaHeader }} />
      )}
      <Wrapper
        as={type === 'internal' ? Link : type === 'external' ? 'a' : 'div'}
        to={type === 'internal' ? getRelativeUrl(internalLink) : undefined}
        href={type === 'external' ? externalLink : undefined}
        target={type === 'external' ? '_blank' : undefined}
      >
        <Inner>
          {type !== 'none' && (
            <Icon type={type}>
              {type === 'external' ? (
                <IconLink />
              ) : (
                <IconChevron direction="right" />
              )}
            </Icon>
          )}
          {image && <Image src={image.sourceUrl} alt={image.altText} />}
          <Content>
            {header && <Header dangerouslySetInnerHTML={{ __html: header }} />}
            {body && <Body dangerouslySetInnerHTML={{ __html: body }} />}
          </Content>
        </Inner>
      </Wrapper>
    </PaddedContent>
  )
}

export const query = `
  type
  internalLink
  externalLink
  header
  body
  metaHeader
  image {
    sourceUrl
    altText
  }
`

const Wrapper = styled.div`
  display: block;
  position: relative;
  padding: 30px 80px 30px 30px;
  background-color: ${props => props.theme.colors.tones.lightest};

  &:hover {
    svg {
      transform: scale(1.2, 1.2);
    }
  }

  ${media.xs`
    padding: 20px 40px 20px 20px;
  `}
`

const Inner = styled.div`
  display: flex;
  align-items: flex-start;

  ${media.xs`
    flex-direction: column;
  `}
`

const Image = styled.img`
  display: block;
  width: 100px;
  height: auto;
  flex-shrink: 0;
  margin-right: 30px;

  ${media.xs`
    margin: 0 auto 30px;
  `}
`

const Content = styled.div`
  width: 100%;
  flex-shrink: 1;
  color: ${props => props.theme.colors.text};
`

const Header = styled(Text).attrs({ as: 'h2', size: 'sm' })`
  margin-bottom: 10px;
`

const Body = styled(Text).attrs({ size: 'sm' })`
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 50px;
  height: 50px;

  background-color: ${props => props.theme.colors.primary};

  svg {
    width: ${props => (props.type === 'internal' ? 8 : 20)}px;
    height: auto;

    transition: 200ms;
  }

  ${media.xs`
    width: 30px;
    height: 30px;

    svg {
      width: ${props => (props.type === 'internal' ? 6 : 12)}px;
    }
  `}
`

const MetaHeader = styled(Text).attrs({ as: 'h2', size: 'sm' })`
  ${responsiveSpacing(1.5)};
`
