import React, { useRef, useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import getRelativeUrl from 'utils/getRelativeUrl'
import { Text } from 'ui/styles/utils'
import IconChevron from 'ui/components/generic/IconChevron'

export default ({ header, body, links }) => {
  const [expanded, setExpanded] = useState(false)
  const [height, setHeight] = useState(0)
  const [headerHeight, setHeaderHeight] = useState(0)

  const headerRef = useRef(null)
  const innerRef = useRef(null)

  const updateHeight = useCallback(() => {
    setHeight(innerRef.current.clientHeight)
    setHeaderHeight(headerRef.current.clientHeight)
  }, [innerRef, headerRef, setHeight, setHeaderHeight])

  useEffect(() => {
    updateHeight()
    window.addEventListener('resize', updateHeight)
    return () => {
      window.removeEventListener('resize', updateHeight)
    }
  }, [updateHeight])

  return (
    <Item height={expanded ? height : headerHeight} expanded={expanded}>
      <Inner ref={innerRef}>
        <Header ref={headerRef} onClick={() => setExpanded(!expanded)}>
          <span dangerouslySetInnerHTML={{ __html: header }} />
          <IconChevron direction={expanded ? 'up' : 'down'} />
        </Header>
        <Content>
          <Body dangerouslySetInnerHTML={{ __html: body }} />
          {links && (
            <Text size="sm">
              {links.map(link => (
                <ReadMore
                  to={getRelativeUrl(link.link)}
                  dangerouslySetInnerHTML={{ __html: link.title }}
                />
              ))}
            </Text>
          )}
        </Content>
      </Inner>
    </Item>
  )
}

const Inner = styled.div``

const Content = styled.div`
  position: relative;
  padding: 0 30px 30px;
  transition: 500ms;
  z-index: 1;
`

const Header = styled(Text).attrs({ as: 'h2', size: 'md' })`
  position: relative;
  padding: 30px 50px 30px 30px;
  margin-bottom: 0;
  cursor: pointer;
  z-index: 2;
  color: ${props => props.theme.colors.primary};

  svg {
    position: absolute;
    top: 30px;
    right: 30px;

    transition: 500ms;

    path {
      stroke: ${props => props.theme.colors.primary};
    }
  }
`

const Body = styled(Text).attrs({ size: 'sm' })``

const ReadMore = styled(Link)`
  font-weight: ${props => props.theme.fontWeights.bold};
  display: block;
`

const Item = styled.div`
  position: relative;
  height: ${props => props.height}px;

  border-top: 1px solid ${props => props.theme.colors.tones.lighter};

  overflow: hidden;

  transition: 500ms;

  &:first-child {
    border-top: none;
  }

  ${Content} {
    opacity: ${props => (props.expanded ? 1 : 0)};
    transform: translateY(${props => (props.expanded ? 0 : -50)}px);
  }
`
