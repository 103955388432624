import React, { Component } from 'react'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { Link } from 'react-router-dom'
import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'

import ArrowButtonComponent from 'ui/components/generic/ArrowButton'
import { Text, Relative } from 'ui/styles/utils'
import getRelativeUrl from 'utils/getRelativeUrl'

class Hero extends Component {
  state = {
    activeSlide: 0,
    prevSlide: null
  }

  render() {
    const { slides } = this.props
    const { activeSlide, prevSlide } = this.state

    const params = {
      loop: true,
      getSwiper: swiper => (this.swiper = swiper),
      on: {
        transitionStart: () => {
          if (this.swiper) {
            this.setState(state => ({
              prevSlide: state.activeSlide,
              activeSlide: this.swiper.realIndex
            }))
          }
        }
      },
      autoplay: slides.length > 1 ? { delay: 5000 } : false

      // infinite: true,
      // speed: 1000,
      // autoplay: true,
      // autoplaySpeed: 5000,
      // dots: false,
      // arrows: false,
      // beforeChange: (oldIndex, newIndex) => {
      //   this.setState(state => ({
      //     prevSlide: oldIndex,
      //     activeSlide: newIndex
      //   }))
      // }
    }

    return (
      <Wrapper>
        <Swiper {...params}>
          {slides.map((slide, index) => (
            <div key={index}>
              <Item active={activeSlide === index} prev={prevSlide === index}>
                <SlideImage image={slide.image.sourceUrl} />
                {slide.hasLink && (
                  <SlideLink to={getRelativeUrl(slide.link)}>
                    <Relative>
                      <SlideHeader size="lg" weight="bold">
                        {slide.header}
                      </SlideHeader>
                      <SlideBody size="sm">{slide.body}</SlideBody>
                    </Relative>
                    <ArrowButton />
                  </SlideLink>
                )}
              </Item>
            </div>
          ))}
        </Swiper>
      </Wrapper>
    )
  }
}

export const query = `
  slides {
    image { sourceUrl }
    header
    body
    hasLink
    link
  }
`

export default Hero

const SlideHeader = Text.withComponent('h1')
const SlideBody = Text

const ArrowButton = styled(ArrowButtonComponent)`
  position: absolute;
  top: 50%;
  right: 50px;
  margin-top: -30px;

  ${media.xs`
    display: none;
  `}
`

const Wrapper = styled.div`
  /* padding-bottom: ${props => props.theme.grid.container.padding}px; */
  overflow: hidden;

  ${media.xs`
    /* padding-bottom: ${props => props.theme.navHeight}px; */
  `}
`

const SlideImage = styled.div`
  height: 100%;
  background-image: url('${props => props.image}');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: grayscale();
`

const SlideLink = styled(Link)`
  display: block;
  position: absolute;
  padding: 20px;
  bottom: ${props => props.theme.grid.container.paddingXs}px;
  right: ${props => props.theme.grid.container.paddingXs * 2}px;
  left: ${props => props.theme.grid.container.paddingXs * 2}px;
  color: #fff;

  ${media.sm`
    bottom: ${props => props.theme.grid.container.padding}px;
    right: ${props => props.theme.grid.container.padding * 2}px;
    left: ${props => props.theme.grid.container.padding * 2}px;
  `}

  ${media.md`
    right: ${props => props.theme.grid.container.padding * 2}px;
    left: 20%;
    bottom: ${props => props.theme.grid.container.padding}px;
    padding: 30px 150px 30px 30px;
  `}

  ${media.lg`
    left: 25%;
  `}

  ${media.xl`
    left: 35%;
  `}

  ${SlideHeader} {
    margin-bottom: 10px;

    ${media.lg`
      margin-bottom: 20px;
    `}
  }

  &:before {
    display: block;
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    background-color: ${props => props.theme.colors.primary};
    opacity: 0.7;
    z-index: 0;
    border-radius: ${props => props.theme.borderRadius}px;
  }

  ${media.xs`

  `}
`

const Item = styled.div`
  height: calc(100vh - ${props => props.theme.navHeight * 3}px);
  min-height: 400px;
  position: relative;

  > div {
    height: 100%;
  }

  ${SlideLink} {
    opacity: ${props => (props.active ? 1 : 0)};
    transform: translateX(
      ${props => (props.active ? 0 : props => (props.prev ? 100 : -50))}px
    );
    transition: 1200ms;
  }
`
