import React, { Component } from 'react'
import styled from 'styled-components'

import { BodyText, Text } from 'ui/styles/utils'
import PaddedContent from 'ui/components/generic/PaddedContent'
import responsiveSpacing from 'ui/styles/responsiveSpacing'

class TextBox extends Component {
  render() {
    const { body, metaHeader } = this.props

    return (
      <Wrapper>
        <PaddedContent size="large">
          {metaHeader && (
            <MetaHeader dangerouslySetInnerHTML={{ __html: metaHeader }} />
          )}
          <Box>
            <BodyText dangerouslySetInnerHTML={{ __html: body }} size="sm" />
          </Box>
        </PaddedContent>
      </Wrapper>
    )
  }
}

export const query = `
  body
  metaHeader
`

const Wrapper = styled.div``

const Box = styled.div`
  padding: 30px;
  background-color: ${props => props.theme.colors.tones.lightest};
`

const MetaHeader = styled(Text).attrs({ as: 'h2', size: 'sm' })`
  ${responsiveSpacing(1.5)};
`

export default TextBox
