import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import responsiveSpacing from 'ui/styles/responsiveSpacing'

export default class Module extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    nextType: PropTypes.string,
    children: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired
  }

  static defaultProps = {
    nextType: null
  }

  getSpacing() {
    const { type, nextType, index, count } = this.props

    // Hero
    if (
      type === 'hero' &&
      (nextType === 'featuredLinks' ||
        nextType === 'linkList' ||
        nextType === 'latestNews' ||
        nextType === 'accordion')
    ) {
      return 0
    }

    // Last module
    if (
      (type === 'featuredLinks' ||
        type === 'linkList' ||
        type === 'latestNews' ||
        type === 'accordion') &&
      index === count - 1
    ) {
      return 0
    }

    return 2
  }

  render() {
    const { children, index } = this.props

    return (
      <Wrapper spacing={this.getSpacing()} index={index}>
        {children}
      </Wrapper>
    )
  }
}

const entry = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`

const Wrapper = styled.div`
  position: relative;
  animation: ${entry} 700ms ${props => props.index * 200 + 200}ms both;
  ${props => responsiveSpacing(props.spacing)};
`
