import React, { Component } from 'react'
import styled from 'styled-components'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'

import { Text } from 'ui/styles/utils'
import getRelativeUrl from 'utils/getRelativeUrl'
import ButtonComponent from 'ui/components/generic/Button'

class FeaturedLinks extends Component {
  render() {
    const { links } = this.props

    const colSpan = links.length === 4 ? 6 : Math.max(4, 12 / links.length)

    return (
      <Wrapper>
        <Container fluid>
          <Row alignItems="stretch">
            {links.map((link, index) => (
              <Col lg={colSpan} key={index}>
                <LinkWrapper>
                  <Link>
                    <div>
                      <Header as="h2">{link.header}</Header>
                      <Body
                        dangerouslySetInnerHTML={{ __html: link.body }}
                        mb={30}
                      />
                    </div>
                    <div>
                      <Button
                        hollow={['alternative', 'secondAlternative'].includes(
                          link.boxLayout
                        )}
                        color={
                          link.boxLayout === 'alternative'
                            ? 'tertiary'
                            : 'primary'
                        }
                        to={getRelativeUrl(link.link)}
                      >
                        Læs mere
                      </Button>
                    </div>
                  </Link>
                  <Box layout={link.box.layout}>
                    <div>
                      <Header as="h2">{link.box.header}</Header>
                      <Body
                        dangerouslySetInnerHTML={{ __html: link.box.body }}
                        mb={30}
                      />
                    </div>
                    <div>
                      <Button
                        hollow={['alternative', 'secondAlternative'].includes(
                          link.box.layout
                        )}
                        color={
                          link.box.layout === 'alternative'
                            ? 'tertiary'
                            : 'primary'
                        }
                        to={getRelativeUrl(link.box.link)}
                      >
                        Læs mere
                      </Button>
                    </div>
                  </Box>
                </LinkWrapper>
              </Col>
            ))}
          </Row>
        </Container>
      </Wrapper>
    )
  }
}

export const query = `
  layout
  links {
    header
    body
    link

    box {
      header
      body
      link
      layout
    }
  }
`

export default FeaturedLinks

const Wrapper = styled.div`
  padding: 50px 0;
  background-color: ${props => props.theme.colors.tones.lightest};

  ${Col} {
    margin-bottom: 30px;
  }
`

const Header = styled(Text).attrs({ size: 'sm' })`
  margin-bottom: 25px;
`

const Body = styled(Text).attrs({ size: 'sm' })``

const LinkWrapper = styled.div`
  border-radius: ${props => props.theme.borderRadius}px;
  overflow: hidden;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.02);
  margin-bottom: 30px;
`

const Link = styled.div`
  padding: 50px;
  background-color: #fff;
  color: ${props => props.theme.colors.textLight};

  ${media.lg`
    display: flex;
    position: relative;
    align-self: stretch;
    justify-content: space-between;
    flex-direction: column;
    min-height: 100%;
  `}
`

const Box = styled(Link)`
  background-color: ${props =>
    props.layout === 'default'
      ? '#fff'
      : props.layout === 'alternative'
      ? props.theme.colors.tertiary
      : props.theme.colors.primary};

  color: ${props =>
    props.layout === 'default' ? props.theme.colors.textLight : '#fff'};
`

const Button = styled(ButtonComponent)``
